import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Link } from "gatsby";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { SafeHtmlParser } from "./safe-html-parser";
import uuid4 from "uuid4";
import ContactForm from "../components/landing-contactFrom";

const LandingGetInTouch = ({
	heading,
	subHeading,
	items,
	images,
	map,
	button,
}) => {
	return (
		<section className={`my-5 my-md-6 my-xl-8`}>
			<Container>
				<Row className="mt-5 justify-content-between">
					<Col md={8} lg={9} xl={10} className="pe-md-5 pe-lg-8">
						<h2 className={`mt-0 mb-2 pb-1 intro-heading`}>{heading}</h2>
						{subHeading && (
							<p className="content-grey text-small">
								<SafeHtmlParser htmlContent={subHeading} />
							</p>
						)}
						{button && (
							<Button
								className="px-4 py-3 mt-4 mb-0 w-fit daxPro-semibold"
								as={Link}
								to={button?.url}
								variant="secondary"
								target={button?.target ?? "_blank"}
							>
								{button?.title ?? "Contact Us"}
							</Button>
						)}

						<div className="iframe-container mt-5">
							<iframe
								src={map}
								frameBorder="0"
								aria-hidden="false"
								title="google-map"
								loading="lazy"
							></iframe>
						</div>
					</Col>
					<Col md={4} lg={3} xl={2} className="mt-4 mt-lg-0 ps-xl-3">
						{items && items.length > 0 && (
							<div>
								{items.map((item) => (
									<div className="pb-3" key={uuid4()}>
										<span className="mb-2 d-block text-lowercase h3 contact-title daxPro-regular">
											{item.heading}
										</span>
										{item.link && item.link.url ? (
											<a
												href={item.link.url}
												className="text-decoration-none text-black"
											>
												<span className="fs-6">{item.text}</span>
											</a>
										) : (
											<div className="text-black">
												{item.text && (
													<SafeHtmlParser htmlContent={item.text} />
												)}
											</div>
										)}
									</div>
								))}
							</div>
						)}

						{images && images.length > 0 && (
							<div class="mt-3">
								{images.splice(0, 1).map((item) => (
									<a
										href={item?.link?.url}
										target={item?.link?.target ?? "_blank"}
										title="Amberth"
										className="mb-3"
									>
										<GatsbyImage
											image={
												item.image?.localFile?.childImageSharp?.gatsbyImageData
											}
											alt={item.image?.altText}
											objectPosition="top"
											style={{ width: "64px", height: "64px" }}
											className="me-3 me-md-4 d-block"
										/>
									</a>
								))}
								<div class="d-flex flex-wrap justify-content-between justify-content-md-start mt-3">
									{images.map((item) => (
										<a
											href={item?.link?.url}
											target={item?.link?.target ?? "_blank"}
											title="Amberth"
											className="mb-3"
										>
											<GatsbyImage
												image={
													item.image?.localFile?.childImageSharp
														?.gatsbyImageData
												}
												alt={item.image?.altText}
												objectPosition="top"
												style={{ width: "64px", height: "64px" }}
												className="me-3 me-md-4"
											/>
										</a>
									))}
								</div>
							</div>
						)}
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default LandingGetInTouch;
