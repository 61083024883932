import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { SafeHtmlParser } from "./safe-html-parser";
import uuid4 from "uuid4";

const TwoColumnSection = ({
	heading,
	content,
	button,
	image,
	halfWidth,
	flip,
	isTheColumnFullWidth,
	isThankYouPage = false,
}) => {
	return (
		<section className={`my-5 my-md-6 my-xl-8 two-column-wrapper`}>
			<Container>
				<Row
					className={`justify-content-center align-items-start align-items-lg-center ${
						flip ? "flex-row-reverse" : ""
					} ${isThankYouPage ? "flex-column-reverse flex-sm-row" : ""}`}
				>
					<Col
						md={halfWidth ? 12 : 5}
						lg={isTheColumnFullWidth ? 12 : halfWidth ? 6 : 4}
					>
						<div className="mb-4" key={uuid4()}>
							<GatsbyImage
								image={image?.localFile?.childImageSharp?.gatsbyImageData}
								style={{ maxHeight: "640px" }}
								alt={image?.altText}
							/>
						</div>
					</Col>
					<Col
						md={halfWidth ? 12 : 7}
						lg={isTheColumnFullWidth ? 12 : halfWidth ? 6 : 8}
						className={
							isTheColumnFullWidth ? "px-3" : flip ? "pe-lg-5" : "ps-lg-5"
						}
					>
						<div
							className={
								isTheColumnFullWidth
									? isThankYouPage
										? "mb-5 mb-sm-0"
										: "mb-0 mt-5"
									: "mb-5"
							}
						>
							<h2
								className={`mt-5 h1 ${
									halfWidth ? "mt-lg-0" : "mt-sm-0"
								} mb-3 intro-heading`}
							>
								{heading}
							</h2>
							{content && (
								<p className="content-grey text-small">
									<SafeHtmlParser htmlContent={content} />
								</p>
							)}

							<Button
								className="px-4 py-3 mt-4 mb-0 w-fit daxPro-semibold"
								as={Link}
								to={button?.url}
								variant="secondary"
								target={button?.target ?? "_blank"}
							>
								{button?.title ?? "Contact Us"}
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default TwoColumnSection;
