import React, { useEffect, useRef } from "react";
import "./contactForm.css";

const QrPageForm = ({ title, formId }) => {
	const formRef = useRef(null);

	const formMappings = {
		bb48c8bfac2d39241fff6a1673bc4cf63bdc8095d14a2ed5a2ccf8ddcdfc96285bbdabb8353b603de1843a9e7b906c61gid01dfed731aaa58dcdaf37f349a802840ca7c2fd0d97d9dbaf7b6111955d3bb7a:
			{
				scriptId: "formScript3551648000064624063",
				formName: "WebToLeads3551648000064624063",
			},
		f4cc0376e546b11bbc5b83822ac0667e310b8cd086e0fd959696517700a83c7f245aef4215b2e8747bb57e9d41716d9bgid717f26cefd23512c5f67f442904143fb53cc9dcb6081cb19e4649bfd567ea626:
			{
				scriptId: "formScript3551648000064624009",
				formName: "WebToLeads3551648000064624009",
			},
		"8dc66ee1b0f6bb5f1b648a5a434c99afd1af71b3fe30a1931447e824c009311ec71dbbcc52912172867fb71966c26e56gid522a241913b9292b7afce24ffd4bc786d358ee6bf76fa713a636af736be867bb":
			{
				scriptId: "formScript3551648000066918001",
				formName: "WebToLeads3551648000066918001",
			},
	};

	const { scriptId: scriptIdNew, formName: formNameNew } = formMappings[
		formId
	] || {
		scriptId: "DEFAULT_SCRIPT_ID",
		formName: "DEFAULT_FORM_NAME",
	};

	useEffect(() => {
		const script = document.createElement("script");
		script.id = scriptIdNew;
		script.src = `https://crm.zoho.com/crm/WebFormServeServlet?rid=${formId}&script=$sYG`;
		script.async = true;
		script.defer = true;

		const handleScriptLoad = () => {
			const insertedForm = document.querySelector(`form[name=${formNameNew}]`);
			if (
				insertedForm &&
				formRef.current &&
				!formRef.current.contains(insertedForm)
			) {
				formRef.current.appendChild(insertedForm);
			}

			// Remove any extraneous divs created by the script
			const extraDivs = document.querySelectorAll("#crmWebToEntityFormLanding");
			extraDivs.forEach((div, index) => {
				if (index > 0) {
					div.remove();
				}
			});
		};

		script.onload = handleScriptLoad;
		document.body.appendChild(script);

		return () => {
			const existingForms = document.querySelectorAll(
				`form[name=${formNameNew}]`
			);
			existingForms.forEach((form) => form.remove());

			if (script.parentNode) {
				script.parentNode.removeChild(script);
			}

			const extraDivs = document.querySelectorAll("#crmWebToEntityFormLanding");
			extraDivs.forEach((div, index) => {
				if (index > 0) {
					div.remove();
				}
			});
		};
	}, []);

	return (
		<div>
			{title && <h3 className="mb-4">{title}</h3>}
			<div
				id="crmWebToEntityFormLanding"
				className="crmWebToEntityForm zcwf_lblLeft"
				ref={formRef}
			>
				{/* The form will be injected here by the Zoho script */}
			</div>
		</div>
	);
};

export default QrPageForm;
