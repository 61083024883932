import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Layout from "../components/layout";
import LandingLayout from "../components/landing-layout";
import { Helmet } from "react-helmet";
import HeroSection from "../components/landing-hero";
import TestimonialList from "../components/testimonials-list";
import TwoColumnSection from "../components/two-column-section";
import LandingGetInTouch from "../components/landing-getInTouch";
import SectionLayout8 from "../components/section-layout-8";
import { Container, Row } from "react-bootstrap";
import BlogCard from "../components/blog-card";
import uuid4 from "uuid4";

const LandingPageTemplate = ({ data: { site, pageData, schemaLogo } }) => {
	const siteUrl = site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Landing",
				item: {
					url: `${siteUrl}/landing`,
					id: `${siteUrl}/landing`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: pageData.title,
				item: {
					url: `${siteUrl}/landing/${pageData.slug}`,
					id: `${siteUrl}/landing/${pageData.slug}`,
				},
			},
		],
	};

	const {
		seoFieldGroups,
		landingPageSettings: { landingPageSettings },
		landingPageFieldsGroup: { landingPageFieldsGroup },
	} = pageData;
	console.log(landingPageSettings?.formId);
	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}/landing/${pageData.slug}`,
		image: `${siteUrl}${seoFieldGroups?.image.publicURL}`,
		mpn: `${siteUrl}/landing/${pageData.slug}`,
		sku: `${siteUrl}/landing/${pageData.slug}`,
		description: `${seoFieldGroups?.productSchema}`,
		logo: `${siteUrl}${schemaLogo.publicURL}`,
		name: "Amberth",
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${schemaLogo.publicURL}`,
			image: `${siteUrl}${seoFieldGroups?.image.publicURL}`,
			name: "Amberth",
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `43`,
			ratingValue: "4.9",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: `${siteUrl}/testimonials`,
			datePublished: "2021-01-08T17:58:29+00:00",
			reviewBody:
				"The whole process was a delight. Everything was taken care of and delivered to the highest quality. We wholeheartedly and without hesitation recommend Amberth.",
			author: {
				"@type": "Person",
				name: "Monique",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "Amberth",
				sameAs: `${siteUrl}`,
			},
		},
	};

	const layoutContent = (
		<>
			{landingPageFieldsGroup &&
				landingPageFieldsGroup.length > 0 &&
				landingPageFieldsGroup.map((sectionInfo) => (
					<>
						{sectionInfo.fieldGroupName ===
							"LandingPage_Landingpagefieldsgroup_LandingPageFieldsGroup_BannerSection" && (
							<HeroSection
								formId={landingPageSettings?.formId || ""}
								heading={sectionInfo?.heading}
								subheading={sectionInfo?.subheading}
								content={sectionInfo?.content}
								backgroundImage={
									sectionInfo?.backgroundImage?.localFile?.childImageSharp
										.gatsbyImageData
								}
								items={sectionInfo?.items}
								testimonials={sectionInfo?.testimonials}
								key={uuid4()}
								isDefaultForm={landingPageSettings?.defaultForm}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"LandingPage_Landingpagefieldsgroup_LandingPageFieldsGroup_SectionLayout2" && (
							<TestimonialList
								heading={sectionInfo?.heading}
								button={sectionInfo?.bottomLink}
								key={uuid4()}
								items={sectionInfo?.items}
							/>
						)}

						{sectionInfo.fieldGroupName ===
							"LandingPage_Landingpagefieldsgroup_LandingPageFieldsGroup_SectionLayout3" && (
							<TwoColumnSection
								heading={sectionInfo?.heading}
								content={sectionInfo?.description}
								button={sectionInfo?.link}
								image={sectionInfo?.image}
								key={uuid4()}
								halfWidth={true}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"LandingPage_Landingpagefieldsgroup_LandingPageFieldsGroup_SectionLayout4" && (
							<>
								{sectionInfo?.items && sectionInfo?.items.length > 0 && (
									<Container>
										<Row className="g-40 mt-4">
											{sectionInfo?.items.map((item) => (
												<BlogCard
													title={item.title}
													image={
														item.projectFields?.projectFeatureImage?.localFile
															.childImageSharp?.gatsbyImageData
													}
													uri={item.uri}
													excerpt={null}
													imageAlt={
														item.projectFields?.projectFeatureImage?.altText
													}
													key={uuid4()}
													textSize="fs-6"
													showReadMore={false}
												/>
											))}
										</Row>
									</Container>
								)}
							</>
						)}
						{sectionInfo.fieldGroupName ===
							"LandingPage_Landingpagefieldsgroup_LandingPageFieldsGroup_SectionLayout5" && (
							<TwoColumnSection
								heading={sectionInfo?.heading}
								content={sectionInfo?.description}
								button={sectionInfo?.link}
								image={sectionInfo?.image}
								key={uuid4()}
								halfWidth={false}
								flip={true}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"LandingPage_Landingpagefieldsgroup_LandingPageFieldsGroup_SectionLayout6" && (
							<LandingGetInTouch
								heading={sectionInfo?.heading}
								items={sectionInfo?.items}
								subHeading={sectionInfo?.subheading}
								key={uuid4()}
								images={sectionInfo?.images}
								map={sectionInfo?.map}
								button={sectionInfo?.link}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"LandingPage_Landingpagefieldsgroup_LandingPageFieldsGroup_SectionLayout7" && (
							<SectionLayout8
								heading={sectionInfo?.heading}
								button={sectionInfo?.button}
								button2={sectionInfo.button2}
								button3={sectionInfo?.button3}
								key={uuid4()}
								items={sectionInfo?.items}
							/>
						)}
					</>
				))}
		</>
	);

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{seoFieldGroups?.productSchema?.length > 0 && (
				<Helmet>
					<script type="application/ld+json">
						{JSON.stringify(serviceSchema)}
					</script>
				</Helmet>
			)}
			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/landing/${pageData.slug}`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@amberthdesign",
					site: "@amberthdesign",
					cardType: "summary",
				}}
				noindex={!landingPageSettings?.disableNoindexAndNofollow}
				nofollow={!landingPageSettings?.disableNoindexAndNofollow}
			/>

			{landingPageSettings?.enableDefaultHeaderAndFooter ? (
				<Layout>{layoutContent}</Layout>
			) : (
				<LandingLayout>{layoutContent}</LandingLayout>
			)}
		</>
	);
};

export default LandingPageTemplate;

export const pageQuery = graphql`
	query ($id: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		schemaLogo: file(relativePath: { eq: "icon.png" }) {
			publicURL
		}
		pageData: wpLandingPage(slug: { eq: $id }) {
			title
			slug
			seoFieldGroups {
				metaTitle
				metaDescription
				localBusinessSchema
				openGraphDescription
				openGraphTitle
				productSchema
				image {
					altText
					publicUrl
					localFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
							original {
								width
								height
							}
						}
					}
				}
			}
			landingPageSettings {
				landingPageSettings {
					defaultForm
					disableNoindexAndNofollow
					enableDefaultHeaderAndFooter
					formId
				}
			}
			landingPageFieldsGroup {
				landingPageFieldsGroup {
					... on WpLandingPage_Landingpagefieldsgroup_LandingPageFieldsGroup_BannerSection {
						content
						fieldGroupName
						heading
						subheading
						backgroundImage {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						testimonials {
							... on WpTestimonial {
								id
								title
								content
								featuredImage {
									node {
										localFile {
											publicURL
											childImageSharp {
												original {
													height
													width
												}
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
						items {
							fieldGroupName
							link {
								target
								title
								url
							}
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpLandingPage_Landingpagefieldsgroup_LandingPageFieldsGroup_SectionLayout2 {
						fieldGroupName
						heading
						bottomLink {
							target
							title
							url
						}
						items {
							... on WpTestimonial {
								id
								title
								content
								featuredImage {
									node {
										localFile {
											publicURL
											childImageSharp {
												original {
													height
													width
												}
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
					}
					... on WpLandingPage_Landingpagefieldsgroup_LandingPageFieldsGroup_SectionLayout3 {
						description
						fieldGroupName
						heading
						link {
							target
							title
							url
						}
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpLandingPage_Landingpagefieldsgroup_LandingPageFieldsGroup_SectionLayout4 {
						fieldGroupName
						items {
							... on WpProject {
								id
								uri
								projectFields {
									projectFeatureImage {
										altText
										localFile {
											publicURL
											childImageSharp {
												original {
													height
													width
												}
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
								title
							}
						}
					}
					... on WpLandingPage_Landingpagefieldsgroup_LandingPageFieldsGroup_SectionLayout5 {
						description
						fieldGroupName
						heading
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						link {
							target
							title
							url
						}
					}
					... on WpLandingPage_Landingpagefieldsgroup_LandingPageFieldsGroup_SectionLayout6 {
						fieldGroupName
						heading
						subheading
						map
						link {
							target
							title
							url
						}
						items {
							text
							heading
							link {
								target
								title
								url
							}
						}
						images {
							link {
								target
								title
								url
							}
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpLandingPage_Landingpagefieldsgroup_LandingPageFieldsGroup_SectionLayout7 {
						fieldGroupName
						heading
						items {
							... on WpFaq {
								id
								title
								slug
								faqFields {
									faqFields {
										answer
										fieldGroupName
										question
									}
								}
							}
						}
						button {
							target
							title
							url
						}
						button2 {
							target
							title
							url
						}
						button3 {
							target
							title
							url
						}
					}
				}
			}
		}
	}
`;
